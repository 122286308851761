import { useEffect, useState } from "react";

import image from '../images/image-icon.ico';
import loading from '../images/progress.gif';
import failed from '../images/failed.ico';
import axios from "axios";

const Player = ({imgdata}) => {
    
    const [activeImage, setActiveImage] = useState({})
    const [fetchedImage, setFetchedImage] = useState(image)

    useEffect(() => {
        setActiveImage(imgdata)
    }, [imgdata])

    useEffect(() => {
        setFetchedImage(loading)
        axios.get(`https://arina.lol/api/win7/acfile/${activeImage.source}`, {responseType: "arraybuffer"}).then((res) => {
            const base64 = btoa(
                new Uint8Array(res.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            setFetchedImage(`data:;base64,${base64}`)
        }).catch((err) => {
            setFetchedImage(image)
        })
    }, [activeImage])
    return (
        <>
            <div id="details" className="screen media-player-grid" style={{height: 'calc(100% - 14px)', background: 'white'}}>
                <div className="file-top-bar">
                    <div>
                        <button style={{color: 'gray'}}>Files ▾</button>
                        <button style={{color: 'gray'}}>Print ▾</button>
                        <button style={{color: 'gray'}}>E-mail</button>
                        <button style={{color: 'gray'}}>Burn ▾</button>
                        <button style={{color: 'gray'}}>Open ▾</button>
                    </div>
                    <div>
                        <button><img alt="decor" width={'16px'} height={'16px'} src="/images/bgs/help.ico" /></button>
                    </div>
                </div>
                <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {activeImage.type === 'image' ? 
                        <a rel="noreferrer" style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} href={activeImage.source !== null ? `/image/${activeImage.source}`: "/images/bgs/welcome.webp"} target="_blank">
                            <img title="Click to open full image in new tab." className="height-100" style={{cursor: 'pointer'}} alt={`${activeImage.short}`} src={fetchedImage} />
                        </a>
                    : 
                        <video className="height-100" controls src={`https://arina.lol/api/win7/acfile/${activeImage.source}`} />
                    }
                </div>
            </div>
        </>
    )
}

export default Player;